import React from 'react';
import type { Member } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import forMembersArea from '../../hoc/for-members-area';
import forUserPrivacyType from '../../hoc/for-user-privacy-type';
import { getProfileUrlByUser } from '../../store/profile-urls/profile-urls-selectors';
import { useSelector, useActions } from '../runtime-context';
import NoopLink from './noop-link';
import SiteLink from './site-link';

type Props = React.PropsWithChildren<{
  user: Member | undefined;
  className?: string;
}>

const ProfileLink: React.FC<Props> = ({ user, className, children }) => {
  const actions = useActions();
  const profileUrl = useSelector((state) => getProfileUrlByUser(state, user));

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    actions.navigateToProfile(user?.siteMemberId, user?.slug);
  };

  return (
    <SiteLink
      onClick={handleClick}
      data-hook="profile-link"
      href={profileUrl}
      className={className}
    >
      {children}
    </SiteLink>
  );
};

export default forMembersArea(
  forUserPrivacyType(ProfileLink),
  NoopLink as typeof ProfileLink,
);
